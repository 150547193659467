<template>
  <div class="flex flex-col relative items-center">
    <ClientOnly>
      <ButtonsIcon
        v-if="canShare"
        outlined
        icon-name="IconShare"
        @click="onShareHandler"
      >
      </ButtonsIcon>
      <TooltipWrapper
        placement="bottom-end"
        tipClasses="w-auto h-auto"
        maxWidth
        v-else
      >
        <ButtonsIcon outlined icon-name="IconShare" @click="onShareHandler">
        </ButtonsIcon>
        <template #content>
          <div class="flex flex-col p-6 py-6 w-[320px] gap-6">
            <div class="flex flex-row justify-between items-center gap-5">
              <a
                :href="shareUrls.twitter.href"
                class="flex flex-col gap-1 items-center"
                target="_blank"
              >
                <Icon name="IconSocialTwitter" size="24"></Icon>
                {{ $t("X / Twitter") }}
              </a>
              <a
                :href="shareUrls.facebook.href"
                class="flex flex-col gap-1 items-center"
                target="_blank"
              >
                <Icon name="IconSocialFacebookFilled" size="24"></Icon>
                {{ $t("Facebook") }}
              </a>
              <a
                :href="shareUrls.whatsapp.href"
                class="flex flex-col gap-1 items-center"
                data-action="share/whatsapp/share"
                target="_blank"
              >
                <Icon name="IconSocialWhatsapp" size="24"></Icon>
                {{ $t("Whatsapp") }}
              </a>
            </div>
            <ButtonsCta
              class="variant-secondary outlined"
              v-if="isSupported"
              @click="copy(source)"
            >
              <!-- by default, `copied` will be reset in 1.5s -->
              <span v-if="!copied">{{ $t("Copy link") }}</span>
              <span v-else class="flex gap-1"
                ><Icon name="IconCheck" class="fill-white" size="24"></Icon>
                {{ $t("Copied!") }}</span
              >
            </ButtonsCta>
          </div>
        </template>
      </TooltipWrapper>

      <slot></slot>
    </ClientOnly>
  </div>
</template>

<script setup>
import { useClipboard } from "@vueuse/core";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
});
// WORKAROUND till share url is fixed from API
const config = {
  title: props?.title ?? "",
  url: window.location.href,
};

const source = ref(config.url);
const { copy, copied, isSupported } = useClipboard({ source });

const canShare = computed(() => navigator.canShare);

const shareUrls = computed(() => {
  const config = {
    title: props?.title ?? "",
    url: window.location.href,
  };
  return useBuildShareUrls(config);
});

const onShareHandler = async () => {
  const didShare = await useNavigatorShare(config);
  if (!didShare) {
    // TODO: handle error
  }
};
</script>
